import {RouteLocationNormalized} from "vue-router";
import {defineNuxtRouteMiddleware} from '#app';
import {useAuthSession} from "../composables/useAuthSession";

export default defineNuxtRouteMiddleware(async (to: RouteLocationNormalized) => {
  if (process.server) {
    return null;
  }
  const {fetchSession, session, isLoggedIn} = useAuthSession();

  try {
    await fetchSession();
  } catch (e) { }

  if (!isLoggedIn.value) {
    sessionStorage.setItem('requestedPath', to.fullPath);
    return navigateTo({path: `/cabinet/auth/sign-in`});
  }
})
