import {inject, injectable} from "inversify";
import {httpTypes} from '@meclee/http/di/types';
import {RuntimeError} from "@meclee/contracts";
import {HttpService} from "@meclee/http";
import {Result} from "neverthrow";
import 'reflect-metadata';
import {ReportService} from "../domain";

@injectable()
export class ApiReportService implements ReportService {
    constructor(
        @inject(httpTypes.HttpService) private readonly httpService: HttpService,
    ) { }

    async downloadXlsx(companyId: string, year: number, month: number): Promise<Result<{file: Blob, name: string}, RuntimeError>> {
        return await this.httpService.post<{file: Blob, name: string}>(`business/analytics/dashboard/${companyId}/report/xlsx`, {
          year,
          month,
        }, {});
    }
}
