import {injectable, inject} from "inversify";
import {notificationsTypes} from "@meclee/notifications/di/types";
import {NotifierService} from "@meclee/notifications";
import {cabinetHrTypes} from "../../../di/types";
import {ReportService} from "../domain";

@injectable()
export class DownloadReportUseCase {
  constructor(
    @inject(cabinetHrTypes.ReportService) private readonly reportService: ReportService,
    @inject(notificationsTypes.NotifierService) private readonly notifierService: NotifierService,
  ) { }

  async downloadXlsx(companyId: string, year: number, month: number): Promise<void> {
    const response = await this.reportService.downloadXlsx(companyId, year, month);

    if (response.isOk()) {
      return response.value;
    }
    if (response.isErr()) {
      this.notifierService.notifyFromHttpError(response.error);
    }
  }
}
